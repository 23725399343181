import { withRouter } from 'react-router';
import logo from '../assets/upulsecc300.png';
function About() {
    return (
        <div style={{display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
        <img src={logo} alt="uPulse.cc logo" style={{opacity: '0.2'}} />
        </div>
    )
}

const AboutwithRouter = withRouter(About);
export default AboutwithRouter; 
