import axios from 'axios';
import React, { useState } from 'react'; // Allows us to use state
export default function SignUp(props) {
    const submit = e => {
        console.log(e);
        let firstname = e.target[0].value;
        let lastname = e.target[1].value;
        let companyname = e.target[2].value;
        let email = e.target[3].value;
        let password = e.target[4].value;
        
        let data = {
            firstname,
            lastname,
            companyname,
            email,
            password
        };
        postUser(data);
    };

    const postUser = data => {
        axios.post("https://upulse.cc:3000/api/user", data)
        .then(d => {
            console.log(d);
            props.history.push('/');
        })
        .catch(err => alert(err));
    };

     

    return (

        <div className="auth-wrapper">
            <form onSubmit={e => {
                e.preventDefault(); 
                submit(e);
                }}>
                <div className="auth-inner">
                    <h3>Sign Up</h3>

                    <div className="form-group">
                        <label>First name</label>
                        <input type="text" name="firstName" className="form-control" placeholder="First name" />
                    </div>

                    <div className="form-group">
                        <label>Last name</label>
                        <input type="text" name="lastName" className="form-control" placeholder="Last name" />
                    </div>

                    <div className="form-group">
                        <label>Company name</label>
                        <input type="text" name="companyName" className="form-control" placeholder="Company name" />
                    </div>

                    <div className="form-group">
                        <label>Email address</label>
                        <input type="email" className="form-control" placeholder="Enter email" />
                    </div>

                    <div className="form-group">
                        <label>Password</label>
                        <input type="password" className="form-control" placeholder="Enter password" />
                    </div>

                    <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                    <p className="forgot-password text-right">
                        Already registered <a href="/login">sign in?</a>
                    </p>
                </div>
            </form>
        </div>
    );
}
