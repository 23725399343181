
import { Container, Row, Column } from "react-bootstrap";

import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

function EntityList() {
    const { user } = useAuth0();

    return (
        <Container className="mb-5">
            <div>Thank you for signing up. this page is still under development.</div>
        </Container>
    );
}

export default withAuthenticationRequired(EntityList);