import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { withRouter } from 'react-router';
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import Home from './components/Home';
import About from './components/About';
import Error from './components/Error';
import Footer from './components/Footer';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Loading from './components/Loading';


import EntityList from './components/EntityList';

import './App.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import initFontAwesome from './utils/initFontAwesome';
import Navigation from './components/Navigation';

initFontAwesome();

function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>
  }


  return (


    <div id="app" className="d-flex flex-column h-100">
      <BrowserRouter>
        <Navigation />
        <Container className="flex-grow-1 mt-5">

          <Switch>
            <Route exact path="/" component={withRouter(Home)} />
            <Route exact path="/about" component={withRouter(About)} />
            <Route exact path="/signup" component={withRouter(SignUp)} />
            <Route exact path="/login" component={withRouter(Login)} />
            <Route exact path="/entitylist" component={withRouter(EntityList)} />
            <Route component={Error} />
          </Switch>
        </Container>
        <Footer />
      </BrowserRouter>
    </div>


  );
}

export default App;
