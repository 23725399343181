import { View, Text } from 'react-native'
export default function Home() {
    return (
        <div
            style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <span style={{fontSize: "4em"}}>Coming Soon...</span>
        </div>
    )
}
