import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavItem, Button, Dropdown } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from '../assets/upulsecc300l.png';

export default function Navigation() {
    const [isOpen, setIsOpen] = useState(false);
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();
    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    return (
        <Navbar bg="dark" variant="dark" expand="lg" style={{ width: '100%', height: '80px'}}>
            <Navbar.Brand as={Link} to="/"><img src={logo} alt="uPulse.cc logo" style={{ width: "80px" }} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    
                    {/* {!isAuthenticated && (<Nav.Link as={Link} to="/pricing">Pricing</Nav.Link>)} */}
                    
                    {isAuthenticated && (
                        <Nav.Link as={Link} to="/entitylist">Monitoring</Nav.Link>
                    )}
                    <Nav.Link as={Link} to="/about">About</Nav.Link>
                </Nav>
                <Nav className="justify-content-end">
                    {!isAuthenticated && (
                        <NavItem>
                            <Button
                                id="upLoginBtn"
                                color="primary"
                                className="btn-margin"
                                onClick={() => loginWithRedirect({screen_hint: "sign up",})}                                
                            >Log in</Button>
                        </NavItem>
                    )}
                    {
                        isAuthenticated && (

                            <Dropdown>
                                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                    <img src={user.picture}
                                        alt="Profile"
                                        className="nav-user-profile rounded-circle"
                                        width="50"
                                    /> {user.name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        id="upLogoutBtn"
                                        onClick={() => logoutWithRedirect()}
                                    >
                                        <FontAwesomeIcon icon="power-off" className="mr-3" /> Log Out

                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
